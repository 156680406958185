import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
    
      path: '/',
      name: 'home',
      component: () => import('@/views/scans/Scans.vue'),
      meta: {
        pageTitle: 'Scans',
        breadcrumb: [
          {
            text: 'Scans',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/promotions/edit/:id',
      name: 'editPromotion',
      component: () => import('@/views/promotion/EditPromotion.vue'),
      meta: {
        pageTitle: 'Modifier une promotion',
        breadcrumb: [
          {
            text: 'Modifier une promotion',
            active: true,
          },
        ],
      },
    },

    {
      path: '/assign-user/:id',
      name: 'editScan',
      component: () => import('@/views/scans/EditScanUser.vue'),
      meta: {
        pageTitle: 'Modifier un scan',
        breadcrumb: [
          {
            text: 'Modifier un scan',
            active: true,
          },
        ],
      },
    },

    {
      path: '/scan/add',
      name: 'editPromotion',
      component: () => import('@/views/scans/AddScan.vue'),
      meta: {
        pageTitle: 'Ajouter un scan',
        breadcrumb: [
          {
            text: 'Ajouter un scan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/personnalisation/:id',
      name: 'personnalisation',
      component: () => import('@/views/personnalisation/Personnalisation.vue'),
      meta: {
        pageTitle: 'Personnalisation',
        breadcrumb: [
          {
            text: 'Personnalisation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/personnalisation-meyro/:id',
      name: 'personnalisation-meyro',
      component: () => import('@/views/personnalisation/PersonnalisationMeyro.vue'),
      meta: {
        pageTitle: 'Personnalisation',
        breadcrumb: [
          {
            text: 'Personnalisation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-review/:id',
      name: 'order-review',
      component: () => import('@/views/order-review/OrderReview.vue'),
      meta: {
        pageTitle: 'Order Review',
        breadcrumb: [
          {
            text: 'Orderreview',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glasses',
      name: 'glasses',
      component: () => import('@/views/glasses/Glasses.vue'),
      meta: {
        pageTitle: 'Glasses',
        breadcrumb: [
          {
            text: 'Glasses',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glasses/:id',
      name: 'glassDetail',
      component: () => import('@/views/glasses/GlassDetail.vue'),
      meta: {
        pageTitle: 'Détail lunette',
        breadcrumb: [
          {
            text: 'Détail lunette',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scans',
      name: 'scans',
      component: () => import('@/views/scans/Scans.vue'),
      meta: {
        pageTitle: 'Scans',
        breadcrumb: [
          {
            text: 'Scans',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scans/detail/:id',
      name: 'scanDetail',
      component: () => import('@/views/scans/ScanDetail.vue'),
      meta: {
        pageTitle: 'Détail scan',
        breadcrumb: [
          {
            text: 'Détail scan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scans/review/:id/:token?',
      name: 'scanReview',
      component: () => import('@/views/scans/ScanReview.vue'),
      meta: {
        pageTitle: 'Détail scan',
        breadcrumb: [
          {
            text: 'Détail scan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/Orders.vue'),
      meta: {
        pageTitle: 'Commandes',
        breadcrumb: [
          {
            text: 'Commandes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders/detail/:id',
      name: 'orderDetail',
      component: () => import('@/views/orders/OrderDetail.vue'),
      meta: {
        pageTitle: 'Détail commande',
        breadcrumb: [
          {
            text: 'Détail commande',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Utilisateurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/view/:id',
      name: 'viewUser',
      component: () => import('@/views/users/ViewUser.vue'),
      meta: {
        pageTitle: 'Information utilisateur',
        breadcrumb: [
          {
            text: 'Information utilisateur',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/edit/:id',
      name: 'editUser',
      component: () => import('@/views/users/EditUser.vue'),
      meta: {
        pageTitle: 'Modification utilisateurs',
        breadcrumb: [
          {
            text: 'Modification utilisateurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/delete/:id',
      name: 'deleteUser',
      component: () => import('@/views/users/DeleteUser.vue'),
      meta: {
        pageTitle: 'Suppression utilisateurs',
        breadcrumb: [
          {
            text: 'Suppression utilisateurs',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/user/add',
      name: 'addUser',
      component: () => import('@/views/users/CreateUser.vue'),
      meta: {
        pageTitle: 'Créer un utilisateur',
        breadcrumb: [
          {
            text: 'Créer un utilisateur',
            active: true,
          },
        ],
      },
    },

    
    {
      path: '/promotions/add',
      name: 'addPromotion',
      component: () => import('@/views/promotion/CreatePromotion.vue'),
      meta: {
        pageTitle: 'Ajouter une promotion',
        breadcrumb: [
          {
            text: 'Ajouter une promotion',
            active: true,
          },
        ],
      },
    },

    {
      path: '/promotions',
      name: 'promotions',
      component: () => import('@/views/promotion/Promotions.vue'),
      meta: {
        pageTitle: 'Promotions',
        breadcrumb: [
          {
            text: 'Promotions',
            active: true,
          },
        ],
      },
    },
    
    
    
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
